import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { DayPickerRangeController } from '@/components/formComponents/DatePickerWrapper/DateRangePickerWrapper';
import clsx from 'clsx';
import moment from 'moment';
import { ChevronDownIcon, DatesIcon, PersonIcon, TrashBinIcon, AddIcon } from '../../Atoms/Icons';
import Button from 'components/Atoms/Button';
import { Modal } from '../Modal';
import { useOuterClick } from '../../../utils';
import Form from './Form';
import InputText from './InputText';
import InputTextarea from './InputTextarea';
import BoxPicker from './BoxPicker';
import { Checkbox, CheckboxList } from './Checkbox';
import { Radiobox, RadioboxList } from './Radio';
import SelectPicker from './SelectPicker';
import CustomInputGuestSelector from './InputGuestPicker';
import Calendar from './Calendar';
import { usePrevious } from 'utils';
import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import { ArrowRightIcon } from 'components/Atoms/Icons';
import { TextField } from './InputText';
import Caret from '@/components/common/icons/Caret';
import { constants } from '@/src/utils/constants';
import { getWeekend } from '@/utils/globalFunc';
import useUpdateLocale from '@/src/utils/customHooks/useUpdateLocale';
import ChildAgeSelector from '@/components/formComponents/GuestSelector/GuestSelectorPopover/ChildAgeSelector';
import router, { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { childAgeUpdate }from "@/redux/actions/childAgeAction";
import DatePickerInOutDates from '@/components/formComponents/DatePickerInOutDates/DatePickerInOutDates';
import ReactModal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTooltip from './CalendarTooltip/CalendarTooltip';
import StartEndCalendarTooltip from './CalendarTooltip/StartEndCalendarTooltip';



// Fuck you motherfucking IDIOT!!!!!
// Guys, use lodash isEqual instead.
// Author of this code is a mentally sick person!!!!
function object_equals( x, y ) {
    if ( x === y ) return true;
      // if both x and y are null or undefined and exactly the same

    if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
      // if they are not strictly equal, they both need to be Objects

    if ( x.constructor !== y.constructor ) return false;
      // they must have the exact same prototype chain, the closest we can do is
      // test there constructor.

    for ( var p in x ) {
      if ( ! x.hasOwnProperty( p ) ) continue;
        // other properties were tested using x.constructor === y.constructor

      if ( ! y.hasOwnProperty( p ) ) return false;
        // allows to compare x[ p ] and y[ p ] when set to undefined

      if ( x[ p ] === y[ p ] ) continue;
        // if they have the same strict value or identity then they are equal

      if ( typeof( x[ p ] ) !== "object" ) return false;
        // Numbers, Strings, Functions, Booleans must be strictly equal

      if ( ! object_equals( x[ p ],  y[ p ] ) ) return false;
        // Objects and Arrays must be tested recursively
    }

    for ( p in y )
      if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) )
        return false;
          // allows x[ p ] to be set to undefined

    return true;
};


export {
    BoxPicker,
    Form,
    InputText,
    InputTextarea,
    Checkbox,
    CheckboxList,
    Radiobox,
    RadioboxList,
    SelectPicker,
    Calendar,
    CustomInputGuestSelector,
}


export const DynamicFormInlineInsert = ({
    enabled,
    input = {
        placeholder: 'Enter a name here'
    },
    button = {
        title: 'add new'
    },
    onSubmit = () => null,
    onCancel = () => null
}) => {
    const [form_enable, setFormEnable] = useState(false);
    const [name, setName] = useState('');
    const input_ref = useRef();
    if (!enabled) return null;

    /**
     * Methods
     */
    const handleSubmit = useCallback(
        () => {
            if(input_ref.current?.value) {
                onSubmit(input_ref.current?.value);
            }
            handleCancel();
        }, [handleCancel, onSubmit]
    );

    const handleCancel = useCallback(
        () => {
            setFormEnable(false);
            setName('');
            onCancel();
        }, [onCancel]
    )

    return (
      <div className="form-inline-insert">
        {
            form_enable
            ? (
                <div className="form">
                    <div className="form__row">
                        <TextField
                            name="name"
                            type="text"
                            placeholder={input.placeholder}
                            autoComplete="off"
                            innerRef={input_ref}
                            value={name}
                            onChange={ele => {
                                if(ele.target.value.trim().length) {
                                    setName(ele.target.value)
                                }
                            }}
                            onKeyPress={e => {
                                if (e.which === 13) {
                                    e.preventDefault();
                                    handleSubmit();
                                }
                            }}
                        />
                    </div>
                    <div className="form__row">
                        <Button
                            title="Cancel"
                            className="n-btn--cancel"
                            onClick={handleCancel}
                        />
                        <Button
                            title="Add"
                            color={name.length >= 3 ? 'vibrant-pink' : 'dark-grey'}
                            disabled={name.length < 3}
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            ) : (
                <Button
                    title={button.title}
                    icon={AddIcon}
                    color="bright-purple"
                    className="n-btn--adding"
                    onClick={() => setFormEnable(true)}
                />
            )
        }
      </div>
    );
};

export const FormInlineInsert = ({
    enabled,
    input = {
        placeholder: 'Enter a name here'
    },
    button = {
        title: 'add new'
    },
    onSubmit = () => null,
    onCancel = () => null
}) => {
    const [form_enable, setFormEnable] = useState(false);
    const [name, setName] = useState('');
    const input_ref = useRef();
    if (!enabled) return null;

    /**
     * Methods
     */
    const handleSubmit = useCallback(
        () => {
            if(input_ref.current?.value) {
                onSubmit(input_ref.current?.value);
            }
            handleCancel();
        }, [handleCancel, onSubmit]
    );

    const handleCancel = useCallback(
        () => {
            setFormEnable(false);
            setName('');
            onCancel();
        }, [onCancel]
    )

    return (
      <div className="form-inline-insert">
        {
            form_enable
            ? (
                <div className="form">
                    <div className="form__row">
                        <InputText
                            name="name"
                            type="text"
                            placeholder={input.placeholder}
                            autoComplete="off"
                            innerRef={input_ref}
                            value={name}
                            onChange={ele => setName(ele.target.value)}
                            onKeyPress={e => {
                                if (e.which === 13) {
                                    e.preventDefault();
                                    handleSubmit();
                                }
                            }}
                        />
                    </div>
                    <div className="form__row">
                        <Button
                            title="Cancel"
                            className="n-btn--cancel"
                            onClick={handleCancel}
                        />
                        <Button
                            title="Add"
                            color={name.length >= 3 ? 'vibrant-pink' : 'dark-grey'}
                            disabled={name.length < 3}
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            ) : (
                <Button
                    title={button.title}
                    icon={AddIcon}
                    color="bright-purple"
                    className="n-btn--adding"
                    onClick={() => setFormEnable(true)}
                />
            )
        }
      </div>
    );
};

export const InputDateRangePicker = ({
    id,
    name,
    placeholder = 'Select dates',
    format = 'DD MMM',
    modalMode,
    initialValue = {
        start: null,
        end: null
    },
    startDatePlaceholderText = 'Check-in',
    endDatePlaceholderText = 'Checkout',
    startDateKey = "start",
    endDateKey = "end",
    icon = DatesIcon,
    doubleDate = false,
    label,
    labelOnFill,
    availablity,
    need_availablity = false,
    open,
    required,
    openUp,
    anchorDirection = 'right',
    onChange = () => null,
    renderInput: InputComponent,
    calendarProps = {},
}) => {
    const nextRouter = useRouter();

    const defaultDaterangeRef = useRef(getWeekend(1));

    const calendarBodyRef = useRef(null);

    const [focus_input, setFocusInput] = useState('startDate');
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);
    const [blocked_part, setBlockedPart] = useState({
        prev_nav: false
    });
    const [availablity_dates, setAvailablityDates] = useState({});
    const [min_night, setMinNight] = useState(1);
    const [is_active, setActive] = useState(false);
    const innerRef = useRef();
    const availablityDatesKeys = useRef(null);
    const Icon = icon;
    const { isDesktop, screenWidth } = useSelector(state => state.device);
    // const { isDesktop } = useDeviceDetect();
    const nextMonthsClicks = useRef(Math.round((constants.calendarMonths+1) / constants.calendarMonthsMobile));
    // Investigation due to reopen https://cuddlynest.atlassian.net/browse/FE-1514
    // useUpdateLocale(moment);

    /**
     * Computed
     */
    const input_text = useMemo(
        () => {
            let text = placeholder;
            if(
                initialValue.start
                && initialValue.end
                && moment(initialValue.start).isValid()
                && moment(initialValue.end).isValid()
              ) {
                text =
                    doubleDate || InputComponent
                    ? ({
                        start: moment(initialValue.start).format(format),
                        end: moment(initialValue.end).format(format)
                    })
                    : `${moment(initialValue.start).format(format)} - ${moment(initialValue.end).format(format)}`;
            }
            return text;
        }, [placeholder, initialValue, format, doubleDate, InputComponent]
    );
    const is_filled = useMemo(
        () => initialValue.start && initialValue.end
        , [initialValue]
    );
    const btn_save_color = useMemo(() => {
        if(modalMode)
            return (start_date && end_date) ? 'vibrant-pink' : 'subtle-grey';

        return (start_date && end_date) ? 'fill-vibrant-pink' : 'fill-subtle-grey';
    }, [start_date, end_date, modalMode]);

    /**
     * Methods
     */
    const handleFocusChange = useCallback(
        (focused_input) => setFocusInput(focused_input)
        , []
    );

    const handleDatesChange = ({ startDate, endDate }) => {
        if (focus_input === 'startDate') {
            setStartDate(startDate);
            setEndDate(null);
        } else if (focus_input === 'endDate') {
            if (!!endDate) {
                setEndDate(endDate);
            } else if (!!startDate) {
                setStartDate(startDate);
                setEndDate(null);
            }
        }

        if(startDate && !endDate) {
            const available_date = availablity_dates[startDate.format('YYYY-MM-DD')];
            if(available_date){
                setMinNight(available_date.mi);
            }
        }
    };

    const handleOpen = () => {
        // TODO: clean this part
        setActive(true);
    };
    const handleClose = () => {
        // TODO: ckean this part
        // handleFocusChange(null);
        setActive(false);
    };
    const handleEnablePrevNav = () => {
        if(blocked_part.prev_nav){
            setBlockedPart(prevState => ({
                ...prevState,
                prev_nav: null
            }));
        }
    };
    const handleBlockPrevNav = (prev_nav) => {
        if(blocked_part.prev_nav !== prev_nav){
            setBlockedPart(prevState => ({
                ...prevState,
                prev_nav
            }));
        }
    }
    /**
    const handleSave = useCallback(
        () => {
            if (start_date && end_date)
                onChange({
                    [startDateKey]: start_date,
                    [endDateKey]: end_date
                });
            handleClose();
        }, [start_date, end_date, onChange, startDateKey, endDateKey]
    )
     */

    const removeParam = useCallback((url, param) => {
        let [pathname, query] = url.split('?');
        query = new URLSearchParams(query);
        query.delete(param);
        query = query.toString();
        return [pathname, query].join('?');
    }, []);

    const handleSave = useCallback(async () => {
        if (start_date && end_date) {
            if (Boolean(nextRouter.query.dates_reset)) {
                const urlUpdate = removeParam(nextRouter.asPath, 'dates_reset');
                await nextRouter.replace(urlUpdate, null, { shallow: true });
            }
            onChange({
                [startDateKey]: start_date,
                [endDateKey]: end_date
            });
        }
        handleClose();
    }, [start_date, end_date, onChange, startDateKey, endDateKey, nextRouter]);


    const isBlockedMinimum = useCallback((day, startDay, minNights) => (
        Boolean(startDay) &&
        day.isAfter(startDay, 'day') &&
        day.isBefore(startDay.clone().add(minNights, 'days'), 'day')
    ), []);

    const isOutsideRange = day => {
        const minDate = calendarProps.hasOwnProperty('minDate')
            ? calendarProps.minDate
            : moment().startOf("day");
        const maxDate = calendarProps.hasOwnProperty('maxDate')
            ? calendarProps.maxDate
            : moment().add(constants.calendarMonths-1, 'months').endOf('month');
        return (
            (moment.isMoment(minDate) ? day.isBefore(minDate) : Boolean(minDate))
            || (moment.isMoment(maxDate) ? day.isAfter(maxDate) : Boolean(maxDate))
        );
    };

    useOuterClick(innerRef, () => {
        if(!modalMode) {
            handleClose()
        }
    });
    /**
     * Events
     */
    useEffect(()=>{
        if (Boolean(nextRouter.query.dates_reset)) {
            setStartDate(null);
            setEndDate(null);
            return;
        }

        if (need_availablity) {
            if (Object.keys(availablity_dates).length) {
                const startDateValid = (!!initialValue.start
                    && moment(initialValue.start).isValid()
                    && availablity_dates[initialValue.start?.format('YYYY-MM-DD')]?.ci !== 0);
                const endDateValid = (!!initialValue.end
                    && moment(initialValue.end).isValid()
                    && availablity_dates[initialValue.end?.format('YYYY-MM-DD')]?.co !== 0
                    && startDateValid);
                if (startDateValid) {
                    setStartDate(initialValue.start);
                }
                if (endDateValid) {
                    const min = (availablity_dates[initialValue.start.format('YYYY-MM-DD')]?.mi || 1);
                    const earliestEndDate = initialValue.start.clone().add(min, 'days');
                    (initialValue.end.isBefore(earliestEndDate, 'day'))
                        ? setEndDate(earliestEndDate)
                        : setEndDate(initialValue.end);
                }
                setMinNight(availablity_dates[initialValue.start.format('YYYY-MM-DD')]?.mi || 1);
            } else {
                setStartDate(defaultDaterangeRef.current[0]);
                setEndDate(defaultDaterangeRef.current[1]);
            }
        } else {
            if(initialValue.start && moment(initialValue.start).isValid())
                setStartDate(initialValue.start);
            if(initialValue.end && moment(initialValue.end).isValid())
                setEndDate(initialValue.end);
        }
    }, [initialValue, availablity_dates, nextRouter]);

    useEffect(()=>{
        if(open) {
            handleOpen();
        }
    }, [open]);


    useEffect(() => {
        if(availablity){
            const dates = {};
            Object.keys(availablity)
                .map(year => {
                    Object.keys(availablity[year]).map(month => {
                        Object.keys(availablity[year][month]).map(day => {
                            dates[`${year}-${month}-${day}`] = availablity[year][month][day];
                        })
                    })
                });
            setAvailablityDates(dates);
        }
        setMinNight(1);
    }, [availablity])

    const [isIosDevice , setIsIosDevice] = useState(false)
    useEffect(()=>{
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setIsIosDevice(true) ;
        }
    },[])

    useEffect(() => {
        if ((!start_date && !end_date) ||
            (!!start_date && !!end_date && modalMode)) {
            setFocusInput('startDate');
        }
    }, [start_date, end_date]);

    useEffect(() => {
        if (!Boolean(nextRouter.query.dates_reset)) {
            return;
        }
        const targetEL = document.querySelector('.js-date-range-input-start-date');
        if (!targetEL) {
            return;
        }
        const coordY = window.scrollY + targetEL.getBoundingClientRect().y - 88 - 68 - 20;
        window.scrollTo({ top: coordY, left: 0, behavior: "smooth" });
        targetEL.click();
        // setStartDate(null);
        // setEndDate(null);
    }, [nextRouter, setStartDate, setEndDate]);

    useEffect(() => {
        if (!!availablity_dates) {
            const sortFn = (a, b) => {
                const aA = a.replaceAll('-', '');
                const bB = b.replaceAll('-', '');
                if (aA > bB) {
                    return 1;
                }
                if (aA < bB) {
                    return -1;
                }
                if (aA == bB) {
                    return 0;
                }
            }
            availablityDatesKeys.current = Object.keys(availablity_dates).sort(sortFn);
        }
    }, [availablity_dates]);


    const dayRespectsMaxConstraint = (startDate, testedDay, arr) => {
        function checkBlock(start, current) {
            // true means day is available
            if (current.isSame(testedDay, 'day')) {
                return !start.clone().add(arr[start.format('YYYY-MM-DD')].mx, 'days').isBefore(current, 'day');
            }
            if (arr[start.format('YYYY-MM-DD')].mx === arr[current.format('YYYY-MM-DD')].mx) {
                // short-circuit evaluation here
                return (
                    !start.clone().add(arr[start.format('YYYY-MM-DD')].mx, 'days').isBefore(current, 'day')
                    && checkBlock(start, current.clone().add(1, 'days'))
                );
            } else {
                // different mx means another period started, so we reset start date
                return checkBlock(current, current.clone().add(1, 'days'));
            }
        }
        return checkBlock(startDate, startDate.clone().add(1, 'days'));
    }

    const calendarSettings = useMemo(() => (
        modalMode
            ? {
                orientation: 'verticalScrollable',
                numberOfMonths: constants.calendarMonths,
                daySize: (screenWidth > 400) ? 52 : 44,
            }
            : {
                numberOfMonths: (screenWidth > 1000) ? 2 : 1,
                daySize: 44,
            }
    ), [modalMode]);


    const CalendarEle = (
        <div ref={calendarBodyRef} className={'date-picker-body'}>
            <DayPickerRangeController
                keepOpenOnDateSelect
                startDate={start_date}
                endDate={end_date}
                onDatesChange={handleDatesChange}
                focusedInput={focus_input}
                onFocusChange={handleFocusChange}
                initialVisibleMonth={() => moment()}
                onNextMonthClick={handleEnablePrevNav}
                minimumNights={min_night}
                minDate={moment().startOf("day")}
                maxDate={moment().add(constants.calendarMonths-1, 'months').endOf('month')}
                renderDayContents={day => {
                    let price = availablity_dates[day.format('YYYY-MM-DD')]?.p;
                    if (price) {
                        price = price.match(/\d+/g)[0];
                        price = new Intl.NumberFormat('en-GB', {
                            notation: "compact",
                            compactDisplay: "short",
                        }).format(price);
                    }

                    const renderDay = () => (
                        <div className={'CalendarDay__inner'} key={day.format('YYYY-MM-DD')}>
                            <span className={'CalendarDay__inner__day'}>
                                {day.format('D')}
                            </span>
                            {!!availablityDatesKeys.current?.length &&
                                <pre className={'CalendarDay__inner__price'}>
                                    {price ?? ' '}
                                </pre>}
                        </div>
                    );

                    if (day.isSame(start_date, 'day')) {
                        return (
                            <StartEndCalendarTooltip
                                start={start_date}
                                end={end_date}
                                open={!!is_active && !!start_date && !end_date}
                                boundaryElement={calendarBodyRef.current}
                                observerElement={document.querySelector('.CalendarMonthGrid')}
                            >
                                {renderDay()}
                            </StartEndCalendarTooltip>
                        );
                    }

                    if (day.isSame(end_date, 'day')) {
                        return (
                            <StartEndCalendarTooltip
                                start={start_date}
                                end={end_date}
                                open={!!is_active && !!start_date && !!end_date}
                                boundaryElement={calendarBodyRef.current}
                                // boundaryElement={'.date-picker-body'}
                                observerElement={document.querySelector('.CalendarMonthGrid')}
                            >
                                {renderDay()}
                            </StartEndCalendarTooltip>
                        );
                    }

                    if (isBlockedMinimum(day, start_date, min_night)) {
                        return (
                            <CalendarTooltip
                                title={
                                    <>
                                    <span>{min_night}</span>-night minimum
                                    </>
                                }
                                disableHoverListener={focus_input === 'startDate'}
                                disableTouchListener={focus_input === 'startDate'}
                            >
                                {renderDay()}
                            </CalendarTooltip>
                        );
                    }

                    const eom = (day.isSame(day.clone().endOf('month'),'day'));
                    const som = (day.isSame(day.clone().startOf('month'),'day'));
                    return (
                        <>
                            {eom && <div className={'CalendarDay__end-of-month'}></div>}
                            {som && <div className={'CalendarDay__start-of-month'}></div>}
                            {renderDay()}
                        </>
                    );
                }}
                isOutsideRange={isOutsideRange}
                isDayBlocked={day => {
                    if(day.format('YYYYMM') === moment().subtract(1, 'months').format('YYYYMM')){
                        handleBlockPrevNav(day.format('YYYYMM'));
                    }

                    if(!need_availablity) return false;

                    if(!Object.keys(availablity_dates).length) return true;

                    const available_date = availablity_dates[day.format('YYYY-MM-DD')];

                    if(!available_date) return true;

                    if(available_date.ci === 0 && available_date.co === 0) {
                        return true;
                    }

                    if (!!start_date && (focus_input === 'endDate') && day.isBefore(start_date, 'day')) {
                        return true;
                    }

                    if (!start_date && (focus_input === 'startDate')) {
                        if (available_date.ci === 0) {
                            return true;
                        }
                    }

                    if (!!start_date && (focus_input === 'endDate')) {
                        if (available_date.co === 0) {
                            return true;
                        }
                    }

                    if (!!start_date && (focus_input === 'endDate') && day.isAfter(start_date, 'day')) {
                        const startIndex = availablityDatesKeys.current.indexOf(start_date.format('YYYY-MM-DD'));
                        const dayIndex = availablityDatesKeys.current.indexOf(day.format('YYYY-MM-DD'));
                        if (startIndex !== -1 && dayIndex !== -1) {
                            const testPeriod = availablityDatesKeys.current.slice(startIndex+1, dayIndex+1);
                            const containsUnavailable = testPeriod.some(el => (availablity_dates[el]?.a === 0));
                            if (containsUnavailable) {
                                return true;
                            }
                        }
                    }

                    if (!!start_date && start_date.isBefore(day, 'day') && (focus_input === 'endDate')) {
                        return !dayRespectsMaxConstraint(start_date.clone(), day.clone(), availablity_dates);
                    }

                    return false;
                }}
                {...calendarSettings}
                {...calendarProps}
            />
        </div>
    );

    const CalendarDates = (
        <div className='date-picker-dates-srp'>
            <div className='date-picker-dates-srp-inner'>
                <DatePickerInOutDates
                    startDate={start_date}
                    endDate={end_date}
                    focused={focus_input}
                    setFocus={handleFocusChange}
                    disabled={{
                        startDate: false,
                        endDate: !Boolean(start_date)
                    }}
                />
            </div>
        </div>
    );

    const availabilityBtn = useMemo(() => (
        <button
            type='button'
            className={clsx('srp-date-picker-availability-btn')}
            disabled={!start_date || !end_date}
            onClick={handleSave}
        >{'Check availability'}</button>
    ), [start_date, end_date, handleSave]);


    const FooterEle = (
        <div className="input-dropdown-footer">
            <Button
                // title="Cancel"
                title={isDesktop ? 'Cancel' : 'Clear'}
                color="black"
                // onClick={handleClose}
                disabled={isDesktop ? false : (!start_date && !end_date)}
                onClick={isDesktop
                    ? handleClose
                    : () => {
                        setStartDate(null);
                        setEndDate(null);
                    }}
                className="btn--cancel"
            />
            <Button
                title="Save"
                color={'cn-pink-violet'}
                onClick={() => ((start_date && end_date) ? handleSave() : null)}
                className="btn--save"
            />
        </div>
    );
    const HeaderEle = (
        <div className='LDP-mobile-calendar-header'>
            <div className='LDP-mobile-calendar-header_title'>Choose dates</div>
            <div className={clsx("LDP-mobile-calendar-header_dates", (!!start_date || !!end_date ) && "LDP-mobile-calendar-header_dates--active")}>
                <span className='LDP-mobile-calendar-header_dates_startdate'>{(start_date && start_date.format(format)) || startDatePlaceholderText}</span>
                <span>→</span>
                <span className='LDP-mobile-calendar-header_dates_enddate'>{(end_date && end_date.format(format)) || endDatePlaceholderText}</span>
            </div>
        </div>
    );
    useEffect(() => {
        if (modalMode && is_active) {
            const verticalScrollableCalendar = document.querySelector('.DayPicker_transitionContainer__verticalScrollable');
            const nextCalendarBar = document.querySelector('.DayPickerNavigation:not(.DayPickerNavigation__verticalScrollable_prevNav)');

            const calendarScrollHandler = ({ target }) => {
                const { scrollHeight, scrollTop, clientHeight } = target;
                if (((scrollHeight - scrollTop - 300) <= clientHeight) && nextCalendarBar && nextMonthsClicks.current > 1) {
                    nextCalendarBar.firstElementChild.click();
                    nextMonthsClicks.current -= 1;
                }
            };
            verticalScrollableCalendar?.addEventListener('scroll', calendarScrollHandler);
            return () => {
                verticalScrollableCalendar?.removeEventListener('scroll', calendarScrollHandler);
            };
        }
    }, [modalMode, is_active]);

    return (
        <div
            className={clsx('n-input-date-range-picker', `open-${anchorDirection}`, {
                'dropdown-mode': !modalMode,
                'modal-mode': modalMode,
                'open-up': openUp,
                'active': is_active,
                'filled': is_filled,
                'error': required && !is_filled,
                'horizontal': !modalMode,
                'disabled-prev-nav': blocked_part.prev_nav,
                'label-on-fill': labelOnFill,
                'double-date': doubleDate
            })}
            ref={innerRef}
        >
            {
                (label && !labelOnFill)
                && <label htmlFor={id || name} className="n-input-date-range-picker__label">{label}</label>
            }
            <div className={clsx("input-value", 'js-date-range-input-start-date')} onClick={handleOpen}>
                {InputComponent
                    ? <InputComponent
                        is_active={is_active}
                        focus_input={focus_input}
                        setFocusInput={setFocusInput}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        start={start_date?.format(format)}
                        end={end_date?.format(format)}
                        // {...input_text}
                        />
                    : (
                        <>
                            {!!icon && (
                                <Icon className="left-icon" />
                            )}
                            <span className="text">
                                {(doubleDate && is_filled)
                                    ? (
                                        <>
                                            <span>{input_text.start}</span>
                                            <ArrowRightIcon />
                                            <span>{input_text.end}</span>
                                        </>
                                    )
                                    : input_text}
                            </span>
                        </>
                    )}
            </div>
            {
                (label && labelOnFill)
                && <label htmlFor={id || name} className="n-input-date-range-picker__label">{label}</label>
            }
            
            {
                !modalMode
                && (
                    <div className='srp-date-picker-calendar'>
                        <div className="input-dropdown">
                            {CalendarDates}
                            {CalendarEle}
                            {FooterEle}
                        </div>
                    </div>
                )
            }

            {modalMode &&
                <>
                    <ReactModal
                        id="date-picker-modal"
                        isOpen={modalMode && is_active}
                        shouldCloseOnEsc
                        shouldCloseOnOverlayClick
                        closeTimeoutMS={300}
                        onRequestClose={() => setActive(false)}
                        ariaHideApp={true}
                        preventScroll={true}
                        portalClassName="date-picker-modal-portal"
                        overlayClassName="date-picker-modal-overlay"
                        className="date-picker-modal-content"
                    >
                        <div className='srp-date-picker-close-wrapper'>
                            <div
                                className='srp-date-picker-close'
                                onClick={() => setActive(false)}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <div className='srp-date-picker-calendar'>
                            {CalendarDates}
                            {CalendarEle}
                            {/* {FooterEle} */}
                        </div>
                        <div className='srp-date-picker-availability-btn-wrapper'>
                            {availabilityBtn}
                        </div>
                    </ReactModal>
                </>
            }

        </div>
    );
}



export const InputGuestRoomPicker = ({
    icon = PersonIcon,
    multiChildrenAge,
    disableChildAge,
    label,
    labelOnFill,
    enableRoom,
    modalMode,
    openUp,
    guestLimit={},
    // limit = {
    //     guest: null,
    //     room: null
    // },
    initialValue = {
        rooms: 0,
        adults: 0,
        infants: 0,
        children: 0,
        multiChildren: []
    },
    placeholder = "Add guests",
    modalClassName = '',
    onChange = () => null,
    onSave = () => null,
    renderInput: InputComponent
}) => {
    const { isMobile } = useDeviceDetect();
    const [active, setActive] = useState(false);
    const [room_count, setRoomCount] = useState(0);
    const [guest_count, setGuestCount] = useState({
        adults: 1,
        infants: 0,
        children: 0,
        multiChildren: [],
        childrenAges:''
    });
    const Icon = icon;
    const pre_initial_value = usePrevious(initialValue);
    const [guestPickerTouched, setGuestPickerTouched] = useState(false);
    const [inputText, setInputText] = useState(placeholder);
    const filledFlag = useRef(false);
    const dispatch = useDispatch();
    const childAge = useSelector(state => state.childAge?.data)
    const childrenAges = router.query.childrenAges
        ?.split(",")
        .filter(Boolean);

    /**
     * Computed
     */
        useEffect(() => {
            const childAgeArray = router.query.childrenAges?.split(",");
            const childArray = childAgeArray?.length ? childAgeArray.map((age) => {
                return {age, guestAgeType: "CHILD"}
            }) : [];
            dispatch(childAgeUpdate(childArray));
        },[router?.query?.childrenAges]);
    const initialGuests = useMemo(
        () => {
            if (!initialValue) return 0;

            return (multiChildrenAge
                ? (parseInt(initialValue.adults ?? 0, 10) + initialValue.multiChildren?.length)
                : (parseInt(initialValue.adults ?? 0, 10) + parseInt(initialValue.children ?? 0, 10))
                // : (parseInt(initialValue.adults ?? 0, 10) + parseInt(initialValue.children ?? 0, 10) + parseInt(initialValue.infants ?? 0, 10))
            )
        },
        [initialValue, multiChildrenAge]
    );
    const getInputText = () => {
        if (!guestPickerTouched) {
            return placeholder;
        } else {
            const guest_count_total = guest_count?.adults + guest_count?.children;
            return `${guest_count_total} guest${guest_count_total > 1 ? 's' : ''}`;
        }
    }
    // const inputText = useMemo(
    //     () => {
    //         let text = placeholder;
    //         if (initialGuests)
    //             text = `${initialGuests} guest${initialGuests > 1 ? 's' : ''}`;
    //         if (enableRoom && !isMobile)
    //             text += ` • ${initialValue.rooms || 0} room${initialValue.rooms > 1 ? 's' : ''}`;
    //         return text.trim();
    //     },
    //     [placeholder, initialGuests, enableRoom, isMobile, initialValue]
    // );
    // const isFilled = useMemo(
    //     () => (inputText !== placeholder.trim())
    //     ,[inputText, placeholder]
    // );
    const value = useMemo(
        () => ({
            ...guest_count,
            multiChildren: guest_count.multiChildren.map(item => item.value),
            rooms: room_count
        })
        ,[guest_count, room_count]
    );
    const enableSubmit = useMemo(
        () => {
            const has_any_guest =
                multiChildrenAge
                ? guest_count.adults + guest_count.multiChildren.length
                : guest_count.adults + guest_count.children + guest_count.infants;

            if(!has_any_guest) return false;
            if(!guestLimit.guestLimit) return true;
            return guestLimit.guestLimit >= guest_count.adults
        },
        [guest_count, guestLimit]
    );


    /**
     * Methods
     */
    const handleClose = () => setActive(false);
    const handleOnSave = useCallback(
        () => {
            const childAges = childAge?.filter(guest => guest.guestAgeType === "CHILD").map(guest => guest.age).join(',');
            value.childrenAges = childAges;
            setInputText(getInputText());
            onSave(value);
            filledFlag.current = true;
            handleClose();
        }, [onSave, value, getInputText, guestPickerTouched]
    );
    const handleGuestCount = useCallback((type, value) => {
        setGuestCount(prevStat=>({
            ...prevStat,
            [type]: value
        }))
    }, []);
    const handleMultiChildrenAdd = (_, stat) => {
        handleGuestCount(
            'multiChildren'
            , (stat > 0) ? [...guest_count.multiChildren, { value: null, active: false }] : guest_count.multiChildren.slice(0, guest_count.multiChildren.length - 1)
        );
    };
    const handleActiveMultiChildren = (indx, active) => {
        handleGuestCount(
            'multiChildren'
            , guest_count.multiChildren.map((item, _indx) => {
                if(_indx === indx){
                    item.active = active;
                }
                return item;
            })
        );
    };
    const handleDeleteMultiChildren = (indx) => {
        handleGuestCount(
            'multiChildren'
            , [...guest_count.multiChildren].filter((item, _indx) => _indx !== indx)
        );
    };
    const handleUpdateMultiChildren = (indx, val) => {
        handleGuestCount(
            'multiChildren'
            , guest_count.multiChildren.map((item, _indx) => {
                // TODO, due to lack of time, I set this in this way,
                if(
                    _indx === indx
                    && (
                        val === ''
                        || (parseInt(val) == val && (val+'').indexOf('.') === -1)
                    )
                ){
                    item.value = val;
                }
                return item;
            })
        );
    };
    const handleClear = useCallback(
        () => {
            setRoomCount(1);
            setGuestCount({
                adults: 1,
                infants: 0,
                children: 0,
                multiChildren: []
            });
            onSave({
                adults: 1,
                infants: 0,
                children: 0,
                rooms: 1,
                multiChildren: []
            });
        }, [setRoomCount, setGuestCount, onSave]
    );

    /**
     * Events
     */
    useEffect(() => {
        if(enableRoom && room_count > guest_count.adults) {
            handleGuestCount('adults', guest_count.adults + 1);
        }
    }, [enableRoom, handleGuestCount, room_count]);
    useEffect(() => {
        if(enableRoom && room_count > guest_count.adults) {
            setRoomCount(prevStat => (--prevStat));
        }
    }, [enableRoom, guest_count]);
    useEffect(() => onChange(value), [onChange, value]);
    useEffect(()=>{
        if(!initialValue || object_equals(initialValue, pre_initial_value)) return;

        setRoomCount(parseInt(initialValue.rooms, 10) || 0);
        setGuestCount({
            adults: parseInt(initialValue.adults, 10) || 0,
            infants: parseInt(initialValue.infants, 10) || 0,
            children: parseInt(initialValue.children, 10) || 0,
            multiChildren: (initialValue.multiChildren || []).map(item => ({ value: item, active: false }))
        });
    }, [initialValue, pre_initial_value]);

    const innerRef = useRef();
    useOuterClick(innerRef, () => { handleClose() });

    const dropdownAction = (
        <>
            {/* <Button
                title="Clear"
                onClick={handleClear}
                color="black"
            /> */}
            <Button
                title="Save"
                color={'fill-vibrant-pink'}
                onClick={handleOnSave}
                className="n-btn--save"
                disabled={!enableSubmit}
            />
        </>
    );
    const dropdown = (
        <InputGuestRoomPickerDropdown
            guest_count={guest_count}
            handleGuestCount={handleGuestCount}
            multiChildrenAge={multiChildrenAge}
            disableChildAge={disableChildAge}
            onActiveMultiChildren={handleActiveMultiChildren}
            onMultiChildrenAdd={handleMultiChildrenAdd}
            onDeleteMultiChildren={handleDeleteMultiChildren}
            onUpdateMultiChildren={handleUpdateMultiChildren}
            enableRoom={enableRoom}
            room_count={room_count}
            setRoomCount={setRoomCount}
            // limit={limit}
            guestLimit={guestLimit}
        >
            {
                !modalMode
                && (
                    <>
                        <li className="divider" />
                        <li className="actions">
                            {dropdownAction}
                        </li>
                    </>
                )
            }
        </InputGuestRoomPickerDropdown>
    );

    return (
        <div
            className={clsx('n-input-guest-room-picker', {
                'n-input-guest-room-picker--label-on-fill': labelOnFill,
                'open-up': openUp,
                'active': active,
                'filled': (guestPickerTouched && filledFlag.current)
            })}
            ref={modalMode ? null : innerRef}
        >
            <div
                className="input-value"
                onClick={() => {
                    setGuestPickerTouched(true);
                    setActive(prevStat => (!prevStat));
                }}
            >
                {InputComponent
                    ? (
                        <InputComponent
                            enableRoom={enableRoom}
                            guests={guest_count}
                            guestCount={initialGuests}
                            roomCount={room_count}
                        />
                    )
                    : (
                        <>
                            <span className="text-group">
                                {icon && <Icon className="left-icon" />}
                                <span className="text">{inputText}</span>
                            </span>
                            <ChevronDownIcon />
                        </>
                    )}
            </div>
            {
                (label && labelOnFill)
                && (
                    <label className="n-input-guest-room-picker__label">{label}</label>
                )
            }
            <div className={clsx('input-dropdown',guest_count.children >= 6 && 'input-dropdown_big_size')}>
                {
                    modalMode
                    ? (
                        <Modal
                            title="Guests"
                            enabled={modalMode && active}
                            className={clsx('n-modal--input-guest-room-picker', modalClassName)}
                            fullScreen={modalMode}
                            onClose={() => setActive(false)}
                        >
                            <Modal.Content>
                                {dropdown}
                            </Modal.Content>
                            <Modal.Footer>
                                {dropdownAction}
                            </Modal.Footer>
                        </Modal>
                    ) : dropdown
                }
            </div>
        </div>
    )
};

const InputGuestRoomPickerDropdown = ({
    guest_count,
    handleGuestCount,
    children,
    disableChildAge,
    multiChildrenAge,
    onMultiChildrenAdd,
    onActiveMultiChildren,
    onDeleteMultiChildren,
    onUpdateMultiChildren,
    room_count,
    setRoomCount,
    enableRoom,
    // limit,
    guestLimit={}
}) => {
    const availableCount = guestLimit.guestLimit - (
        multiChildrenAge
        ? guest_count.adults + guest_count.multiChildren.length
        : guest_count.adults + guest_count.children + guest_count.infants
    );
    const dispatch = useDispatch();

    // temporarily commented out - pls do not delete
    // const guestCapVR = {
    //     adults: (guestLimit.adultsLimit
    //         ? Math.min((guest_count.adults + availableCount), guestLimit.adultsLimit)
    //         : (guest_count.adults + availableCount)),
    //     children: (guestLimit.childrenLimit
    //         ? Math.min((guest_count.children + availableCount), guestLimit.childrenLimit)
    //         : (guest_count.children + availableCount)),
    //     infants: (guestLimit.infantsLimit
    //         ? Math.min((guest_count.infants + availableCount), guestLimit.infantsLimit)
    //         : (guest_count.infants + availableCount)),
    // };
    const guestCapVR = {
        adults: guestLimit.adultsLimit,
        children: guestLimit.childrenLimit,
        infants: guestLimit.infantsLimit
    };
    const guestCapHotel = {
        adults: constants.guestInput[constants.guestTypes.ADULT]['MAX'],
        children: constants.guestInput[constants.guestTypes.CHILD]['MAX'],
        infants: constants.guestInput[constants.guestTypes.INFANT]['MAX']
    };
    const guestCap = (enableRoom || Object.keys(guestLimit).length === 0) ? guestCapHotel : guestCapVR;
    const childAgeArray = router.query.childrenAges
        ?.split(",")
        .filter(Boolean);
    const childArray = childAgeArray?.length ? childAgeArray.map((age) => {
        return {age, guestAgeType: "CHILD"}
    }) : [];

    return (
        <ul>
            <li>
                <span>Adults</span>
                <InputCounter
                    noState
                    min={constants.guestInput[constants.guestTypes.ADULT]['MIN']}
                    max={guestCap.adults}
                    value={guest_count.adults}
                    onChange={(val) => handleGuestCount('adults', val)}
                />
            </li>
            {
                multiChildrenAge
                ?
                <>
                    <li>
                        <span>Children<small>Ages 2-17</small></span>
                        <InputCounter
                            noState
                            min={constants.guestInput[constants.guestTypes.CHILD]['MIN']}
                            max={constants.guestInput[constants.guestTypes.CHILD]['MAX']}
                            value={guest_count.multiChildren.length || 0}
                            onChange={onMultiChildrenAdd}
                        />
                    </li>
                    {
                        guest_count.multiChildren
                        .map((item, indx) => (
                            <li key={indx} className="li-input">
                                <TextField
                                    label={`Child ${indx+1}`}
                                    placeholder={`Child ${indx+1} age`}
                                    labelOnFill
                                    value={
                                        item.active
                                        ? (item.value  || '') // to avoid the null
                                        : (
                                            ((item.value + '').trim() !== '' && item.value !== null)
                                            ? `${item.value} years old`
                                            : ''
                                        )
                                    }
                                    onChange={e => onUpdateMultiChildren(indx, e.target.value.replace(' years old', ''))}
                                    onKeyPress={e => {
                                        if (e.which === 13) {e.preventDefault()}
                                    }}
                                    onFocus={() => onActiveMultiChildren(indx, true)}
                                    onBlur={() => {
                                        onActiveMultiChildren(indx, false);
                                        if(+item.value > 17) {
                                            onUpdateMultiChildren(indx, '17')
                                        }else if (+item.value < 3) {
                                            onUpdateMultiChildren(indx, '3')
                                        }
                                    }}
                                />
                                <span onClick={() => onDeleteMultiChildren(indx)}>
                                    <TrashBinIcon />
                                </span>
                                {
                                    (item.value !== null  && (+item.value < 3 || +item.value > 17))
                                    && (<label className="error">Child age should be between 3 and 17</label>)
                                }
                            </li>
                        ))
                    }
                </>
                :
                <>
                    <li>
                        <span>Children<small>Ages 2-17</small></span>
                        {/* <span>Children<small>Under age 17</small></span> */}
                        <InputCounter
                            noState
                            value={guest_count.children || 0}
                            min={constants.guestInput[constants.guestTypes.CHILD]['MIN']}
                            max={guestCap.children || 0}
                            onChange={(val) => handleGuestCount('children', val)}
                        />
                    </li>
                    {!disableChildAge &&
                        <li className={!guest_count.children && 'zero_children'}>
                            <ChildAgeSelector
                                childArray={childArray}
                                numberOfChildren={guest_count.children}
                                updatePrimaryChildArray={(val) => dispatch(childAgeUpdate(val))}
                            />
                        </li>}
                    <li>
                        <span>Infants<small>Under age 2</small></span>
                        <InputCounter
                            noState
                            min={constants.guestInput[constants.guestTypes.INFANT]['MIN']}
                            max={guestCap.infants || 0}
                            value={guest_count.infants || 0}
                            onChange={(val) => handleGuestCount('infants', val)}
                        />
                    </li>
                </>
            }
            {
                enableRoom
                &&
                <>
                    <li className="divider" />
                    <li className="room">
                        <span>Rooms</span>
                        <InputCounter
                            noState
                            min={constants.guestInput[constants.guestTypes.ROOM]['MIN']}
                            max={constants.guestInput[constants.guestTypes.ROOM]['MAX']}
                            value={room_count}
                            onChange={val => setRoomCount(val)}
                        />
                    </li>
                </>
            }
            {
                !!guestLimit?.guestLimit
                && (
                    <li className="limit">
                        <small>{guestLimit.guestsDesc || `${guestLimit?.guestLimit} guest${guestLimit?.guestLimit > 1 ? 's' : ''} maximum`}</small>
                    </li>
                )
            }
            {children}
        </ul>
    );
}

export const InputTagSelector = ({
    items = [],
    initialValue = [],
    onChange = () => null
}) => {
    const [selected, setSelected] = useState([]);

    /**
     * Comnputed
     */
    const isSelected = (item) => selected.find(select => select.key === item.key);

    /**
     * Methods
     */
    const handleClick = (item) => {
        const new_selected = isSelected(item)
            ? selected.filter(select => select.key !== item.key)
            : [...selected, item];

        setSelected(new_selected);
        onChange(new_selected);
    }

    /**
     * Events
     */
    useEffect(() => {
        if(initialValue?.length) {
            setSelected(initialValue);
        }
    }, [initialValue])

    return (
        <div className="n-input-tag-selector">
            <ul>
                {items.map(item =>
                    <li
                        key={item.key}
                        onClick={() => handleClick(item)}
                        className={isSelected(item) ? 'active' : ''}
                    >{item.name}</li>
                )}
            </ul>
        </div>
    );
}

export const InputCounter = ({
    className,
    label,
    suffix,
    prefix,
    value,
    step = 1,
    onChange = () => null,
    max = 100,
    min = 0,
    noState = false
}) => {
    const [counter, setCounter] = useState(value);
    const onAdd = useCallback(() => {
        onChange(currentValue() + step, 1);
        if(!noState)
            setCounter(counter + step);
    }, [counter, currentValue, noState, onChange, step]);

    const onSub = useCallback(() => {
        onChange(currentValue() - step, -1);
        if(!noState) {
            setCounter(counter - 1);
        }
    }, [counter, currentValue, noState, onChange, step]);
    const currentValue = useCallback(() => noState ? value : counter);

    const isDisabled = useMemo(() => {
        const currValue = currentValue();
        return (currValue <= min) || (currValue >= max);
    }, [currentValue, max, min]);

    return (
        <div className={clsx('n-input-counter', className, {
            'n-input-counter--labeled': label,
            'n-input-counter--disabled': isDisabled
        })}>
            <div className="n-input-counter__wrapper">
                {label && <div className="n-input-counter_label">{label}</div>}
                <button
                    className="btn-decrease"
                    type="button"
                    onClick={onSub}
                    disabled={currentValue() <= min}
                >
                    <Caret className={clsx('counter-carret-icon', 'counter-carret-icon--dec')} />
                </button>
                <span
                    className={clsx('n-input-counter_value', {
                        'n-input-counter--disabled': isDisabled
                    })}
                >{`${prefix || ''}${currentValue()}${suffix || ''}`}</span>
                <button
                    className="btn-increase"
                    type="button"
                    onClick={onAdd}
                    disabled={currentValue() >= max}
                >
                    <Caret className={clsx('counter-carret-icon', 'counter-carret-icon--asc')} />
                </button>
            </div>
        </div>
    );
};
